import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/jotai/esm/react.mjs");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/jotai/esm/react/utils.mjs");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/src/app/[lang]/(with-footer)/rooms/RoomDetails.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/src/lib/components/GoogleTagManagerViewPage/GTMViewPageEvent.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/lib/components/Link/index.tsx");
